import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class Hire extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Hire me for your next project" />
        <header className="px-4 lg:px-0 pt-10 md:pt-24 pb-10">
          <h1 className="text-2xl">
            I am available for hire for remote, freelance software development
            and technical writing projects.
          </h1>
        </header>
        <section className="text-gray-900">
          <div className="mx-auto px-4 lg:px-0 text-lg">
            <p className="mb-5">
              Some examples of work I can assist you with are the following:
            </p>
            <ul className="mb-5 list-disc list-inside space-y-2">
              <li>
                <strong>MVPs</strong>: I can help with the development of a
                Minimum Viable Product (MVP) or proof-of-concept to enable you
                to quickly test your idea in the market and determine whether it
                is viable.
              </li>
              <li>
                <strong>Authentication</strong>: I can assist you with the
                implementation of modern authentication and authorization flows
                using protocols such as OAuth 2.0, OpenID Connect and JSON Web
                Tokens
              </li>
              <li>
                <strong>Technology updates</strong>: I can give guidance and
                assistance on migrating from ASP.NET MVC and .NET Framework to
                ASP.NET Core, or from earlier versions of ASP.NET Core to the
                latest long-term support (LTS) releases.
              </li>
            </ul>
            <p className="mb-10">
              To discuss your project and see whether we are a good fit for each
              other, please email me at{" "}
              <a
                href="mailto:jerrie@jerriepelser.com"
                className="text-blue-500 hover:underline"
              >
                jerrie@jerriepelser.com
              </a>
              .
            </p>
          </div>
        </section>
      </Layout>
    )
  }
}

export default Hire

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
